import React, { useState } from 'react'
import VerifyPhone from 'components/pages/ProfilePage/PhoneForm/VerifyPhone'
import ConfirmationCodeForm from 'components/pages/ProfilePage/PhoneForm/ConfirmationCodeForm'
import Title from 'components/ui/Title'
import { setReportingUser } from 'utils/rollbar'
import { useApolloClient, useMutation } from '@apollo/client'
import getMutationError from 'utils/getMutationError'
import cn from 'classnames'
import { logEvent, setAdsUserData } from 'utils/analytics'

import {
  customerPhoneLogin,
  customerPhoneLogin_customerPhoneLogin_customer as Customer
} from './types/customerPhoneLogin'
import SignupForm from './SignupForm'
import CUSTOMER_PHONE_LOGIN from './customerPhoneLogin'

type Props = {
  onLogin: (customer: Customer, redirectTo?: string | null) => void
  signup?: boolean
  catering?: boolean
  formOnly?: boolean
}

function LoginForm({
  onLogin,
  signup = true,
  catering = false,
  formOnly = false
}: Props) {
  const client = useApolloClient()
  const [phone, setPhone] = useState('')
  const [phoneToken, setPhoneToken] = useState<string | null>(null)
  const [verifying, setVerifying] = useState(false)
  const [customerPhoneLoginMutation] = useMutation<customerPhoneLogin>(
    CUSTOMER_PHONE_LOGIN
  )
  const [showSignup, setShowSignup] = useState(false)
  const [formError, setFormError] = useState<string | null>(null)

  const afterCustomerLogin = async (
    customer: Customer,
    redirectTo: string | null,
    newUser: boolean
  ) => {
    setReportingUser(customer)
    await client.resetStore()
    onLogin(customer, redirectTo)
    await setAdsUserData(customer)
    if (newUser) {
      logEvent('sign_up', {
        method: 'phone'
      })
      // sign_up_ads
      logEvent('conversion', {
        send_to: 'AW-768772864/XyAZCISZoIkZEICWyu4C'
      })
    } else {
      logEvent('login', {
        method: 'phone'
      })
    }
  }

  const onVerificationSent = async (newPhone: string) => {
    setPhone(newPhone)
    setVerifying(true)
    setFormError(null)
  }

  const onChangePhone = () => {
    setVerifying(false)
    setShowSignup(false)
    setPhoneToken(null)
    setFormError(null)
  }

  const onPhoneToken = async (token: string) => {
    setVerifying(false)
    setFormError(null)
    setPhoneToken(token)
    const response = await customerPhoneLoginMutation({
      variables: { phoneToken: token }
    })
    const error = getMutationError(response)
    if (error) {
      setFormError(error)
      return null
    }
    const { customer, redirectTo } = response.data!.customerPhoneLogin!
    if (customer) {
      setFormError(null)
      await afterCustomerLogin(customer, redirectTo, false)
    } else if (signup) {
      setFormError(null)
      setShowSignup(true)
    } else {
      setFormError('No user with that phone exists')
    }
    return null
  }

  return (
    <div className={cn({ 'bg-white md:mx-0 p-6 mx-4 rounded-xl': !formOnly })}>
      {!formOnly && (
        <Title variant="sectionTitle">
          {catering ? (
            'Contact Information'
          ) : (
            <>
              Sign in
              {signup && ' / Sign Up'}
            </>
          )}
        </Title>
      )}
      {formError && <p className="text-red-500 mb-4 italic">{formError}</p>}
      <VerifyPhone
        buttonName="Next"
        catering={catering}
        onChangePhone={onChangePhone}
        onVerificationSent={onVerificationSent}
        verified={showSignup}
        verifying={verifying}
      />
      {verifying && (
        <ConfirmationCodeForm onSuccess={onPhoneToken} phone={phone} />
      )}
      {showSignup && phoneToken && (
        <SignupForm
          catering={catering}
          onSuccess={afterCustomerLogin}
          phoneToken={phoneToken}
        />
      )}
    </div>
  )
}

export default LoginForm
