import { gql } from '@apollo/client'

export default gql`
  mutation verificationCodeVerify($phone: String!, $code: String!) {
    verificationCodeVerify(phone: $phone, code: $code) {
      phoneToken
      errors {
        fullMessage
        key
        message
      }
    }
  }
`
