import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import CheckBox from 'components/ui/CheckBox'

type CheckBoxProps = {
  description?: string
  label: React.ReactElement | string
}

export type Props = CheckBoxProps & {
  name: string
  size?: 'base' | 'large'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
}

function InnerField({
  description,
  label,
  input,
  size,
  meta: { touched, error: syncError, submitError }
}: FieldRenderProps<boolean, HTMLInputElement> & CheckBoxProps) {
  const error = syncError || submitError
  const showError = (touched || submitError) && !!error
  return (
    <CheckBox
      description={description}
      error={showError ? error : null}
      label={label}
      onChange={(value) => {
        input.onChange(value)
        input.onBlur()
      }}
      size={size}
      value={input.value}
    />
  )
}

export default function FormCheckBox({
  name,
  fieldProps = {},
  ...innerProps
}: Props) {
  return (
    <Field<
      boolean,
      FieldRenderProps<boolean, HTMLInputElement>,
      HTMLInputElement
    >
      name={name}
      render={(props) => <InnerField {...props} {...innerProps} name={name} />}
      {...fieldProps}
    />
  )
}
