import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import React from 'react'
import Button from 'components/ui/Button'
import FormError from 'components/ui/FormError'
import FormCheckBox from 'components/ui/FormCheckBox'
import FieldSpacer from 'components/ui/FieldSpacer'
import {
  customerPhoneSignup,
  customerPhoneSignup_customerPhoneSignup_customer as CustomerType
} from 'components/LoginForm/types/customerPhoneSignup'
import CUSTOMER_PHONE_SIGNUP from 'components/LoginForm/customerPhoneSignup'
import { useMutation } from '@apollo/client'
import FormTextField from 'components/ui/FormTextField'
import getMutationErrors from 'utils/getMutationErrors'

type Props = {
  phoneToken: string
  onSuccess: (
    customer: CustomerType,
    redirectTo: string | null,
    newUser: boolean
  ) => void
  catering: boolean
}
type SubmitValues = {
  firstName?: string
  lastName?: string
  email?: string
  tos?: boolean
  phoneTos?: boolean
}
type SubmitErrors = {
  firstName?: string
  lastName?: string
  email?: string
  tos?: string
  phoneTos?: string
  [FORM_ERROR]?: string
}

const SignupForm = ({ phoneToken, onSuccess, catering }: Props) => {
  const validate = (values: SubmitValues): SubmitErrors => {
    const errors: SubmitErrors = {}
    if (!values.firstName) {
      errors.firstName = 'Required'
    }
    if (!values.lastName) {
      errors.lastName = 'Required'
    }
    if (!values.email) {
      errors.email = 'Required'
    }
    if (!values.tos) {
      errors.tos = 'Required'
    }
    if (!values.phoneTos) {
      errors.phoneTos = 'Required'
    }
    return errors
  }

  const [customerPhoneSignupMutation] = useMutation<customerPhoneSignup>(
    CUSTOMER_PHONE_SIGNUP
  )

  const onSubmit = async (values: SubmitValues) => {
    const { firstName, lastName, email } = values
    const response = await customerPhoneSignupMutation({
      variables: { phoneToken, firstName, lastName, email }
    })
    const mutationErrors = getMutationErrors(response, values)

    if (mutationErrors) return mutationErrors

    const { customer, redirectTo } = response.data!.customerPhoneSignup!
    if (onSuccess) {
      onSuccess(customer!, redirectTo, true)
    }
    return null
  }

  return (
    <div className="mt-10">
      <Form<SubmitValues> onSubmit={onSubmit} validate={validate}>
        {({ handleSubmit, submitting }) => (
          <>
            <FormTextField label="First Name" name="firstName" />
            <FieldSpacer variant="tight" />
            <FormTextField label="Last Name" name="lastName" />
            <FieldSpacer variant="tight" />
            <FormTextField autoComplete="email" label="Email" name="email" />
            <FieldSpacer variant="tight" />
            <FormCheckBox
              label={
                <div className="flex text-sm italic text-gray-500">
                  <p>I Agree to </p>
                  <a
                    className="underline text-orange-500 hover:text-orange-700 ml-1"
                    href="https://www.bestfoodtrucks.com/terms"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>Terms of Service</p>
                  </a>
                </div>
              }
              name="tos"
            />
            <br />
            <FormCheckBox
              label={
                <div className="flex text-sm italic text-gray-500">
                  I agree to receive SMS message updates. <br />
                  We will only text you about services you have requested.{' '}
                  <br />
                  Msg & Data rates may apply. Text STOP to cancel, HELP for
                  information.
                </div>
              }
              name="phoneTos"
            />
            <FieldSpacer variant="normal" />
            <FormError className="mb-10" />
            <div className="flex justify-center px-4 py-3 -m-6">
              <Button
                color="primary"
                disabled={submitting}
                onClick={handleSubmit}
                variant="contained"
              >
                {catering ? 'Continue' : 'Create Account & Continue'}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  )
}
export default SignupForm
