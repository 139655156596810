import React from 'react'
import cn from 'classnames'

type TitleProps = {
  children:
    | (React.ReactElement | string | null)
    | (React.ReactElement | string | null)[]
  variant: 'sectionTitle' | 'pageTitle'
  margin?: boolean
  className?: string
  align?: 'center' | 'left'
}

const Title = ({
  children,
  variant,
  className,
  margin = true,
  align = 'center'
}: TitleProps) => (
  <h1
    className={cn(
      'text-gray-900',
      {
        'text-center': align === 'center',
        'my-8': margin,
        'text-2xl font-medium': variant === 'sectionTitle',
        'text-3xl font-bold': variant === 'pageTitle'
      },
      className
    )}
  >
    {children}
  </h1>
)

export default Title
