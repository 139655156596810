import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import VERIFICATION_CODE_VERIFY from 'components/pages/ProfilePage/PhoneForm/verificationCodeVerify'
import { verificationCodeVerify } from 'components/pages/ProfilePage/PhoneForm/types/verificationCodeVerify'
import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import getMutationError from 'utils/getMutationError'

type Props = {
  onSuccess: (phoneToken: string) => void
  phone: string
}

export default function ConfirmationCodeForm({ onSuccess, phone }: Props) {
  const [code, setCode] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [verifyCode] = useMutation<verificationCodeVerify>(
    VERIFICATION_CODE_VERIFY
  )

  const onVerifyCode = async () => {
    if (!code) return
    const response = await verifyCode({ variables: { code, phone } })
    const mutationError = getMutationError(response)
    if (mutationError) {
      setError(mutationError)
    } else {
      onSuccess(response.data!.verificationCodeVerify!.phoneToken!)
    }
  }
  return (
    <>
      <div className="flex items-end justify-between mb-5">
        <div className="flex-grow">
          <TextField
            description="Enter a code from SMS"
            error={error}
            name="Confirmation Text"
            onChange={(event) => setCode(event.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode !== 13) {
                return
              }
              onVerifyCode()
            }}
            placeholder="6 digit code"
            value={code}
          />
        </div>
        <div className="flex-grow mt-1 ml-4">
          <Button
            color="primary"
            onClick={onVerifyCode}
            size="input"
            variant="contained"
            fullWidth
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  )
}
