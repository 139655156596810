import React, { useState } from 'react'
import { verificationCodeSend } from 'components/pages/ProfilePage/PhoneForm/types/verificationCodeSend'
import { useMutation } from '@apollo/client'
import VERIFICATION_CODE_SEND from 'components/pages/ProfilePage/PhoneForm/verificationCodeSend'
import formatPhone from 'utils/formatPhone'
import Button from 'components/ui/Button'
import TextField from 'components/ui/TextField'
import CheckBox from 'components/ui/CheckBox'
import getMutationError from 'utils/getMutationError'

const isPhone = (value: string) => /\(\d{3}\)\s\d{3}-\d{4}/.test(value)

type Props = {
  onVerificationSent: (phone: string) => void
  onChangePhone: () => void
  verifying: boolean
  initialPhone?: string
  buttonName: string
  catering: boolean
  showTerms?: boolean
  verified?: boolean
}

export default function VerifyPhone({
  onVerificationSent,
  verifying,
  onChangePhone,
  initialPhone = '',
  showTerms = false,
  buttonName,
  catering,
  verified = false
}: Props) {
  const [prevPhone, setPrevPhone] = useState(initialPhone)
  const [phone, setPhone] = useState(initialPhone)
  const [error, setError] = useState<string | undefined>(undefined)
  const [tosError, setTosError] = useState<boolean>(false)
  const [agreedToTos, setAgreedToTos] = useState<boolean>(false)
  const [sendCode] = useMutation<verificationCodeSend>(VERIFICATION_CODE_SEND)
  const hasNotChanged =
    initialPhone?.length > 0 && initialPhone === phone?.replace(/[()\s-]/g, '')

  const onVerifyPhone = async () => {
    if (showTerms && !agreedToTos) {
      setTosError(true)
      return
    }
    setTosError(false)

    if (prevPhone === phone) {
      onVerificationSent(phone)
      return
    }
    setPrevPhone(phone)
    const response = await sendCode({ variables: { phone } })
    const mutationError = getMutationError(response)
    if (mutationError) {
      setError(mutationError)
    } else {
      setError(undefined)
      onVerificationSent(phone)
    }
  }

  const description = catering ? (
    <span>
      We just need to verify via SMS code that you&apos;re not a robot.
      {!showTerms && (
        <>
          <br />
          Msg & Data rates may apply.
        </>
      )}
    </span>
  ) : (
    <span>
      Requires SMS confirmation.
      {!showTerms && (
        <>
          <br />
          Msg & Data rates may apply.
        </>
      )}
    </span>
  )

  const nextDisabled =
    !isPhone(formatPhone(phone)) || hasNotChanged || verified || verifying

  return (
    <>
      <div className="flex items-end justify-between mb-5">
        <div className="flex-grow">
          <TextField
            autoComplete="tel"
            description={description}
            disabled={verifying || verified}
            error={error}
            name="Phone"
            onChange={(event) => setPhone(formatPhone(event.target.value))}
            onKeyDown={(e) => {
              if (e.keyCode !== 13) {
                return
              }
              if (nextDisabled) {
                return
              }
              onVerifyPhone()
            }}
            placeholder="Phone"
            value={formatPhone(phone)}
          />
        </div>

        <div className="flex-grow mt-1 ml-4">
          <Button
            color="primary"
            disabled={nextDisabled}
            onClick={onVerifyPhone}
            size="input"
            variant="contained"
            fullWidth
          >
            {buttonName}
          </Button>
        </div>
      </div>

      {showTerms && !verifying && (
        <CheckBox
          error={tosError ? 'Required' : undefined}
          label={
            <div className="flex text-sm italic text-gray-500">
              I agree to receive SMS message updates. <br />
              We will only text you about services you have requested. <br />
              Msg & Data rates may apply. Text STOP to cancel, HELP for
              information.
            </div>
          }
          onChange={(newValue) => {
            setAgreedToTos(newValue)
            setTosError(!newValue)
          }}
          value={agreedToTos}
        />
      )}

      {(verifying || verified) && (
        <div className="-mt-4 mb-5">
          <Button
            color="default"
            onClick={() => {
              setError(undefined)
              onChangePhone()
            }}
            size="small"
            variant="anchor"
          >
            Use another number
          </Button>
        </div>
      )}
    </>
  )
}
