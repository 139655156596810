import { gql } from '@apollo/client'

export default gql`
  mutation customerPhoneSignup(
    $phoneToken: String!
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    customerPhoneSignup(
      phoneToken: $phoneToken
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      customer {
        id
        authenticationToken
        email
        completeProfile
        anonymous
        phone
        firstName
        lastName
      }
      redirectTo
      errors {
        fullMessage
        key
        message
      }
    }
  }
`
