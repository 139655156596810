import cn from 'classnames'

type Props = {
  variant: 'normal' | 'wide' | 'tight'
}

const FieldSpacer = ({ variant = 'normal' }: Props) => (
  <div
    className={cn('', {
      'my-8': variant === 'normal',
      'my-10': variant === 'wide',
      'my-4': variant === 'tight'
    })}
  />
)

export default FieldSpacer
