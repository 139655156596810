import React, { useContext } from 'react'
import cn from 'classnames'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'

export type Props = {
  label?: React.ReactElement | string
  description?: string
  value: boolean
  onChange: (value: boolean) => void
  error?: string | null
  size?: 'base' | 'large'
  disabled?: boolean
}

export default function CheckBox({
  label,
  description,
  error,
  value,
  onChange,
  disabled = false,
  size = 'base'
}: Props) {
  const { albertsons } = useContext(ShiftPageContext)
  return (
    <div className="w-full">
      <label className="inline-flex items-center">
        <input
          checked={value}
          className={cn(
            'rounded border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 scroll-offset',
            {
              'text-orange-500 focus:border-orange-500 focus:ring-orange-500': !albertsons,
              'border-red-500 ring ring-red-500 ring-opacity-50': error,
              'text-albertsons-secondary1 focus:border-albertsons-secondary1 focus:ring-albertsons-secondary1': albertsons,
              'w-5 h-5': size === 'large',
              'opacity-40': disabled
            }
          )}
          disabled={disabled}
          onChange={() => onChange(!value)}
          type="checkbox"
        />
        <span className="ml-2">{label}</span>
      </label>
      {error && (
        <div>
          <p className="text-sm italic text-red-500 font-light mt-1">{error}</p>
        </div>
      )}
      {description && (
        <div>
          <p className="text-sm italic text-gray-500 font-light mt-1">
            {description}
          </p>
        </div>
      )}
    </div>
  )
}
