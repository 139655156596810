import { gql } from '@apollo/client'

export default gql`
  mutation verificationCodeSend($phone: String!) {
    verificationCodeSend(phone: $phone) {
      errors {
        fullMessage
        key
        message
      }
    }
  }
`
