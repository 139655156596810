import { gql } from '@apollo/client'

export default gql`
  mutation customerPhoneLogin($phoneToken: String!) {
    customerPhoneLogin(phoneToken: $phoneToken) {
      customer {
        id
        authenticationToken
        email
        completeProfile
        anonymous
        phone
        firstName
        lastName
      }
      redirectTo
      errors {
        fullMessage
        key
        message
      }
    }
  }
`
